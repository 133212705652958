// prefer default export if available
const preferDefault = m => (m && m.default) || m

exports.components = {
  "component---node-modules-gatsby-plugin-offline-app-shell-js": () => import("./../../../node_modules/gatsby-plugin-offline/app-shell.js" /* webpackChunkName: "component---node-modules-gatsby-plugin-offline-app-shell-js" */),
  "component---src-pages-404-js": () => import("./../../../src/pages/404.js" /* webpackChunkName: "component---src-pages-404-js" */),
  "component---src-pages-index-js": () => import("./../../../src/pages/index.js" /* webpackChunkName: "component---src-pages-index-js" */),
  "component---src-templates-home-page-js": () => import("./../../../src/templates/home-page.js" /* webpackChunkName: "component---src-templates-home-page-js" */),
  "component---src-templates-imprint-page-js": () => import("./../../../src/templates/imprint-page.js" /* webpackChunkName: "component---src-templates-imprint-page-js" */),
  "component---src-templates-portfolio-page-js": () => import("./../../../src/templates/portfolio-page.js" /* webpackChunkName: "component---src-templates-portfolio-page-js" */),
  "component---src-templates-project-page-js": () => import("./../../../src/templates/project-page.js" /* webpackChunkName: "component---src-templates-project-page-js" */)
}

